import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'

import * as styles from './ArticleMainList.module.scss'

import SocialMedia from '../../UI/SocialMedia/SocialMedia'
import NewsArticle from '../NewsArticleCard'
import SimpleButton from '../../UI/Buttons/SimpleButton/SimpleButton'

export default function ArticleMainList({ location, list, pageContext }) {
  const [selectState, setSelectState] = useState(pageContext.slug)

  const handleSelectChange = (e) => {
    e.preventDefault()
    setSelectState(e.target.value)
    let url = '/news#top'
    if (e.target.value !== 'All') {
      url = `/news/${e.target.value}#top`
    }
    navigate(url)
  }

  return (
    <>
      <section
        id="top"
        className={`inlineChildren_lgMobile alignChildrenCenter ${styles.news_navBar}`}
      >
        <section className={styles.news_mobileNav}>
          <select onChange={handleSelectChange} value={selectState}>
            <option value="All">All Articles</option>
            <option value="press-release">Press Releases</option>
            <option value="giving-back">Giving Back</option>
            <option value="veterans">Veterans</option>
            <option value="corporate">Corporate</option>
            <option value="people-of-precise">#PeopleofPrecise</option>
          </select>
        </section>
        <section className={styles.news_nav}>
          <Link
            to={`/news#top`}
            className={`${styles.news_navLink} ${
              !pageContext.slug ? styles.active : ''
            }`}
          >
            All
          </Link>
          <Link
            to={`/news/press-release#top`}
            className={`${styles.news_navLink} ${
              pageContext.slug === 'press-release' && styles.active
            }`}
          >
            Press Releases
          </Link>
          <Link
            to={`/news/giving-back#top`}
            className={`${styles.news_navLink} ${
              pageContext.slug === 'giving-back' && styles.active
            }`}
          >
            Giving Back
          </Link>
          <Link
            to={`/news/veterans#top`}
            className={`${styles.news_navLink} ${
              pageContext.slug === 'veterans' && styles.active
            }`}
          >
            Veterans
          </Link>
          <Link
            to={`/news/corporate#top`}
            className={`${styles.news_navLink} ${
              pageContext.slug === 'corporate' && styles.active
            }`}
          >
            Corporate
          </Link>
          <Link
            to={`/news/people-of-precise#top`}
            className={`${styles.news_navLink} ${
              pageContext.slug === 'people-of-precise' && styles.active
            }`}
          >
            #PeopleofPrecise
          </Link>
        </section>
        <section className={styles.news_socialMedia}>
          <SocialMedia color="gray" />
        </section>
      </section>
      {list && (
        <>
          <section className={'inlineChildren_smMobile flexWrap'}>
            {list.edges.map(({ node }, idx) => {
              if (idx === 0 && pageContext.pageNumber === 0) {
                return (
                  <NewsArticle
                    key={node.uri}
                    post={node}
                    currentPage={location}
                    size="lg"
                  />
                )
              }
              return (
                <NewsArticle
                  key={node.uri}
                  post={node}
                  currentPage={location}
                />
              )
            })}
          </section>
          <section
            className={
              'inlineChildren_smMobile alignChildrenCenter justifyChildrenEnd'
            }
          >
            <div className={styles.news_btnWrap}>
              {pageContext.previousPagePath !== '' && (
                <SimpleButton
                  text="Previous"
                  type="gray"
                  trailingIcon={false}
                  url={pageContext.previousPagePath}
                />
              )}
              {pageContext.nextPagePath !== '' && (
                <SimpleButton
                  text="Next"
                  type="solidRed"
                  url={pageContext.nextPagePath}
                />
              )}
            </div>
          </section>
        </>
      )}
    </>
  )
}
